@import "../../settings/variables.css";

.container-default {
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 30px;
  width: 92%;
  height: auto;
  background: white;
  object-fit: contain;
  border-radius: 1px;
  box-shadow: 0 1px 8px 0 #e8ebf2;
}

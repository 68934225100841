@import "../../settings/variables.css";

.topnav-container {
  margin: 0 auto;
  width: 94.9%;
  height: 60px;
  float: left;
  background: white;
  border-bottom: 0.5px solid #e7e9ea;
}

.ui.styled.accordion,
.ui.styled.accordion .accordion {
  width: 100%;
  border: none;
}
.ui.styled.accordion,
.ui.styled.accordion .accordion {
  background: #fff;
  border: none;
  box-shadow: none;
}

.ui.accordion .title:not(.ui) {
  padding: 0.5em 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
}

.ui.corner.label {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  border-color: rgba(0, 0, 0, 0);
  width: 4em;
  height: 4em;
  z-index: 1;
  transition: border-color 0.1s ease;
}

.image-gallery-slide {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.image-gallery-slide img {
  margin: 0 auto;
  width: 60%;
}

.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 20%;
  line-height: 1;
  padding: 10px 20px;
  white-space: normal;
}
.image-gallery-thumbnails-container {
  margin: 0 auto;
  text-align: center;
  width: 300px;
}

@import "../../settings/variables.css";

.nav-title {
  width: 80px;
  height: 26px;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #233539;
  color: var(--dark);
}

.title-modal {
  margin: 10px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #233539;
  color: var(--dark);
  vertical-align: middle;
  text-align: left;
}

@import "../../settings/variables.css";

.sidebar {
  margin: 0 auto;
  width: 10%;
  height: 100%;
  padding: 5px;
  background-color: var(--grey-2);
  text-align: center;
}

.sidebar-container {
  margin: 0 auto;
  width: 5%;
  height: 100%;
  float: left;
  background: white;
  text-align: center;
}

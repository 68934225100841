@import "../../settings/variables.css";

.navigation {
  margin: 20px;
  padding: 10px;
  background: #e5e5e5;
  float: right;
  color: #6b6b6b;
  font-size: 10px;
  border-radius: 5px;
}

.navigation-left {
  margin: 0px;
  padding: 10px;
  background: #e5e5e5;
  float: right;
  color: #6b6b6b;
  font-size: 10px;
  float: left;
}

.navigation-inner .link-active {
  margin: 0 auto;
  color: var(--dusty-red);
}

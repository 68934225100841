.gantt-container {
  min-height: 400px;
}

/* Red taskbar */
.gantt_bar_task {
  border-radius: 15px;
  background: #c33b44;
  border: none;
}

.gantt_task_progress {
  border-radius: 15px;
  background: #83282e;
  border: none;
}

.gantt_btn_set.gantt_left_btn_set.gantt_save_btn_set {
  background: #295896;
  color: white;
  text-shadow: none;
}

.gantt_btn_set.gantt_left_btn_set.gantt_delete_btn_set {
  background: #b3373f;
  color: white;
  text-shadow: none;
}

.gantt_btn_set.gantt_left_btn_set.gantt_cancel_btn_set {
  background: #9b9b9b;
  color: white;
  text-shadow: none;
}

.gantt_cancel_btn {
  color: #9b9b9b;
}

.gantt_layout_cell.gantt_layout_root.gantt_layout.gantt_layout_y.gantt_container.gantt_layout_cell_border_left.gantt_layout_cell_border_top.gantt_layout_cell_border_right.gantt_layout_cell_border_bottom {
  border: none;
}

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
  background-color: #e6e6e6;
}

.gantt_row.gantt_row_task:hover {
  background-color: #e6e6e6;
}

.gantt_row.odd.gantt_row_task:hover {
  background-color: #e6e6e6;
}

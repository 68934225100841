@import url("https://fonts.googleapis.com/css?family=Open+Sans");

:root {
  /*
    * Colors
    */
  --dusk-blue: #295896;
  --dusty-red: #b3363e;
  --white: #d4d4d4;
  --white2: #d7d7d7;
  --white3: #eaeaea;
  --dark: #233539;
  --active-blue: #e0ecfa;
  --success: #45d95f;
  --danger: #ff4634;
  --grey-1: #dddddd;
  --grey-2: #f6f6f6;
  --grey-3: #f7f7f7;
  --grey-4: #d4d4d4;
  --grey-5: #646464;
  --grey-6: #666666;
  --grey-7: #e8e8e8;
  --grey-8: #a3a3a3;
  /*
    * Fonts
    */
  --base-font-family: "Open Sans", sans-serif;
}

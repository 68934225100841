@import "../../settings/variables.css";

.horizontal {
  margin: 0 auto;
  text-align: left;
  font-family: var(--base-font-family);
  margin-left: 50px;
}

.vertical {
  margin: 0 auto;
  text-align: left;
  font-family: var(--base-font-family);
  margin-left: 10px;
}

@import "../../settings/variables.css";

.tab-navigation {
  margin: 10px;
  padding: 5px 10px;
  background: #e5e5e5;
  float: right;
  color: #6b6b6b;
  font-size: 10px;
  border-radius: 5px;
}

.tab-navigation.center {
  float: none;
  display: inline-block;
}

.tab-navigation .link {
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 10px;
  color: #9b9b9b;
  cursor: pointer;
  display: inline-block;
}

.tab-navigation .link:hover{  
  color: #1e70bf;
}

.tab-navigation .link-active, .tab-navigation.secondary .link-active {
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 10px;
  color: #af383c;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.tab-navigation.secondary .link-active {  
  color: #1e70bf;  
}
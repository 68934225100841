@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.navigation {
  margin: 0px;
  /* width: 300px; */
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
  background: #e5e5e5;
  color: #6b6b6b;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
}

.navigation-right {
  margin: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
  background: #e5e5e5;
  color: #6b6b6b;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
  float: right;
}

.navigation-inner .link-active {
  margin: 0 auto;
  color: #b3363e;
}

:root {
  /*
    * Colors
    */
  --dusk-blue: #295896;
  --dusty-red: #b3363e;
  --white: #d4d4d4;
  --white2: #d7d7d7;
  --white3: #eaeaea;
  --dark: #233539;
  --active-blue: #e0ecfa;
  --success: #45d95f;
  --danger: #ff4634;
  --grey-1: #dddddd;
  --grey-2: #f6f6f6;
  --grey-3: #f7f7f7;
  --grey-4: #d4d4d4;
  --grey-5: #646464;
  --grey-6: #666666;
  --grey-7: #e8e8e8;
  --grey-8: #a3a3a3;
  /*
    * Fonts
    */
  --base-font-family: "Open Sans", sans-serif;
}

.link {
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 12px;
  color: #9b9b9b;
  cursor: pointer;
}
.link-active {
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  color: var(--dusk-blue);
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.link-active-long {
  text-decoration: none;
  padding-left: 5px;
  padding-right: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  color: var(--dusk-blue);
  background: var(--active-blue);
  border-radius: 4px;
}

.link-bold {
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey-5);
}
.link-active-bold {
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-blue);
  background: var(--active-blue);
  border-radius: 4px;
}

.tab-navigation {
  margin: 10px;
  padding: 5px 10px;
  background: #e5e5e5;
  float: right;
  color: #6b6b6b;
  font-size: 10px;
  border-radius: 5px;
}

.tab-navigation.center {
  float: none;
  display: inline-block;
}

.tab-navigation .link {
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 10px;
  color: #9b9b9b;
  cursor: pointer;
  display: inline-block;
}

.tab-navigation .link:hover{  
  color: #1e70bf;
}

.tab-navigation .link-active, .tab-navigation.secondary .link-active {
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 10px;
  color: #af383c;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.tab-navigation.secondary .link-active {  
  color: #1e70bf;  
}
.body-conatiner {
  margin: 0 auto;
  width: 94.9%;
  height: 89%;
  float: left;
  background: #fbfbfb;
  overflow-y: auto;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  margin: 5px auto;
  padding: 0;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 #eaeaea;
  background-color: white;
}

.divider {
  margin: 0 auto;
  width: 1px;
  height: 20px;
  margin-top: 5px;
  background-color: #dddddd;
}

.default {
  padding: 10px;
  width: 100px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--grey-1);
  color: var(--grey-1);
  cursor: pointer;
}

.action-btn {
  padding: 8px;
  width: 150px;
  background: var(--dusk-blue);
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.ui.button.disabled {
  padding: 8px;
  /*width: 150px;*/
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(41, 88, 150, 0.55);
}

.action-btn-big {
  padding: 10px;
  width: 200px;
  background: var(--primary-blue);
  border: none;
  color: white;
  cursor: pointer;
  font-size: 15px;
}

.action-btn-outline {
  margin: 10px;
  padding: 8px;
  width: 150px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--dusk-blue);
  color: var(--dusk-blue);
  cursor: pointer;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
}

.cancel-btn {
  padding: 8px;
  width: 150px;
  background: var(--grey-4);
  border: none;
  color: var(--grey-6);
  cursor: pointer;
  border-radius: 5px;
}

.icon-btn {
  padding: 10px;
  width: 40px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--grey-1);
  color: var(--grey-6);
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.icon-btn-active {
  padding: 10px;
  width: 40px;
  background: var(--grey-5);
  border: none;
  color: var(--grey-1);
  cursor: pointer;
  border-radius: 5px;
}

.icon-btn-round-primary {
  padding: 5px;
  width: 30px;
  height: 30px;
  background: var(--primary-blue);
  border: none;
  border-radius: 45px;
  color: white;
  cursor: pointer;
}

.icon-btn-round-success {
  padding: 5px;
  width: 30px;
  height: 30px;
  background: var(--success);
  border: none;
  border-radius: 45px;
  color: white;
  cursor: pointer;
}

.icon-btn-round-gray {
  padding: 1px;
  width: 15px;
  height: 15px;
  background: var(--grey-4);
  border: none;
  border-radius: 60px;
  color: white;
  cursor: pointer;
  font-size: 12px;
}

.icon-btn-round-danger {
  padding: 8px;
  width: 150px;
  background: #aa3232;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.icon-btn-edit {
  width: 30px;
  height: 30px;
  border: none;
  color: var(--primary-blue);
  font-size: 18px;
  cursor: pointer;
}

.icon-btn-delete {
  width: 30px;
  height: 30px;
  border: none;
  color: var(--danger);
  font-size: 18px;
  cursor: pointer;
}

.btn-group {
  margin: 0;
  flex: 1 1;
  padding: 5px;
  border: none;
  border-right: none;
  border-radius: 2px;
  text-align: center;
  font-size: 15px;
  color: var(--white2);
  cursor: pointer;
  background-color: white;
}

.btn-group:focus {
  outline: none;
}

.small {
  width: 30px;
}

.container-default {
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 30px;
  width: 92%;
  height: auto;
  background: white;
  object-fit: contain;
  border-radius: 1px;
  box-shadow: 0 1px 8px 0 #e8ebf2;
}

i.black.icon {
  color: #1890ff !important;
}

.flex-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.flex-group-col {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.flex-children {
  flex: 1 1;
  margin: 10px;
  flex: 1 0 21%; /* limit to max 4 children per row */
}

.header-nav {
  margin: 0 auto;
  width: 100%;
  height: 50px;
  padding: 5px;
  border-bottom: 1px solid var(--grey-4);
}

.nav-title {
  width: 80px;
  height: 26px;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #233539;
  color: var(--dark);
}

.title-modal {
  margin: 10px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #233539;
  color: var(--dark);
  vertical-align: middle;
  text-align: left;
}

.horizontal {
  margin: 0 auto;
  text-align: left;
  font-family: var(--base-font-family);
  margin-left: 50px;
}

.vertical {
  margin: 0 auto;
  text-align: left;
  font-family: var(--base-font-family);
  margin-left: 10px;
}

.modal-overlay {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  display: none;
  z-index: 9999;
  color: #646464;
}

.topnav-container {
  margin: 0 auto;
  width: 94.9%;
  height: 60px;
  float: left;
  background: white;
  border-bottom: 0.5px solid #e7e9ea;
}

.navigation {
  margin: 20px;
  padding: 10px;
  background: #e5e5e5;
  float: right;
  color: #6b6b6b;
  font-size: 10px;
  border-radius: 5px;
}

.navigation-left {
  margin: 0px;
  padding: 10px;
  background: #e5e5e5;
  float: right;
  color: #6b6b6b;
  font-size: 10px;
  float: left;
}

.navigation-inner .link-active {
  margin: 0 auto;
  color: var(--dusty-red);
}

.sidebar {
  margin: 0 auto;
  width: 10%;
  height: 100%;
  padding: 5px;
  background-color: var(--grey-2);
  text-align: center;
}

.sidebar-container {
  margin: 0 auto;
  width: 5%;
  height: 100%;
  float: left;
  background: white;
  text-align: center;
}

.logo {
  margin: 5px;
}

.table-default {
  margin: 0 auto;
  width: 95%;
  font-family: var(--base-font-family);
  text-align: center;
  font-size: 10px;
  color: var(--grey-5);
  border-collapse: collapse;
  border-spacing: 10px;
}



.thead {
  margin: 15px auto;
  border-bottom: 1px solid var(--grey-5);
  cursor: pointer;
}

.tr {
  margin: 15px auto;
  padding: 1px;
  border-bottom: 1px solid var(--grey-4);
  color: var(--grey-8);
}
.td {
  margin: 10px;
}

.default-label {
  font-family: var(--base-font-family);
}

.gantt-container {
  min-height: 400px;
}

/* Red taskbar */
.gantt_bar_task {
  border-radius: 15px;
  background: #c33b44;
  border: none;
}

.gantt_task_progress {
  border-radius: 15px;
  background: #83282e;
  border: none;
}

.gantt_btn_set.gantt_left_btn_set.gantt_save_btn_set {
  background: #295896;
  color: white;
  text-shadow: none;
}

.gantt_btn_set.gantt_left_btn_set.gantt_delete_btn_set {
  background: #b3373f;
  color: white;
  text-shadow: none;
}

.gantt_btn_set.gantt_left_btn_set.gantt_cancel_btn_set {
  background: #9b9b9b;
  color: white;
  text-shadow: none;
}

.gantt_cancel_btn {
  color: #9b9b9b;
}

.gantt_layout_cell.gantt_layout_root.gantt_layout.gantt_layout_y.gantt_container.gantt_layout_cell_border_left.gantt_layout_cell_border_top.gantt_layout_cell_border_right.gantt_layout_cell_border_bottom {
  border: none;
}

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
  background-color: #e6e6e6;
}

.gantt_row.gantt_row_task:hover {
  background-color: #e6e6e6;
}

.gantt_row.odd.gantt_row_task:hover {
  background-color: #e6e6e6;
}

.ui.styled.accordion,
.ui.styled.accordion .accordion {
  width: 100%;
  border: none;
}
.ui.styled.accordion,
.ui.styled.accordion .accordion {
  background: #fff;
  border: none;
  box-shadow: none;
}

.ui.accordion .title:not(.ui) {
  padding: 0.5em 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
}

.ui.corner.label {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  border-color: rgba(0, 0, 0, 0);
  width: 4em;
  height: 4em;
  z-index: 1;
  -webkit-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
}

.image-gallery-slide {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.image-gallery-slide img {
  margin: 0 auto;
  width: 60%;
}

.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 20%;
  line-height: 1;
  padding: 10px 20px;
  white-space: normal;
}
.image-gallery-thumbnails-container {
  margin: 0 auto;
  text-align: center;
  width: 300px;
}

body {
  /**********************************
  * Prevent elements from get selected on mouse drag
  ***********************************/
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

/**********************************
* Variables Project
***********************************/
:root {
  --font-color: #646464;
  --btn-color-primary: #295896;
  --border-color-wrapper: #f2f2f2;
  --border-color-default: #d9d9d9;
  --color-placeholder: #9b9b9b;
  --color-title-module: #233539;
  --color-green: #23a71e;
  --color-green-two: #7ed320;
  --color-red: #b3373f;
  --color-red-hover: #c33b44;
  --color-white: #ffffff;
  --color-background: #fbfbfb;
  --color-linner: #dddddd;
}
/**********************************
* Container With Pages Components
***********************************/
.Wrapper {
  margin: 0 auto !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.Wrapper__Row {
  margin: 0 auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Wrapper__SideBar {
  background: white !important;
  border-right: 1px solid #f2f2f2 !important;
  border-right: 1px solid var(--border-color-wrapper) !important;
}

.Wrapper__Body {
  background-color: #fbfbfb !important;
  background-color: var(--color-background) !important;
}

.Wrapper__TopBar {
  height: 65px !important;
  background: white !important;
  border-bottom: 1px solid #f2f2f2 !important;
  border-bottom: 1px solid var(--border-color-wrapper) !important;
}

.Wrapper__Nav {
  margin-top: 2rem !important;
  align-items: center !important;
}

.Wrapper__Nav-Title {
  font-family: "Open Sans";
  border: 0 !important;
  font-weight: 700 !important;
  font-size: 38px !important;
  letter-spacing: -1.09px !important;
  background-color: transparent !important;
  color: #233539 !important;
  color: var(--color-title-module) !important;
}

.Wrapper__Modal {
  margin-top: 2rem !important;
}

.Wrapper__Modal.Summary .Modal__Header .row:first-child {
  display: none !important;
}

.Wrapper__Modal.Summary .Modal {
  border: none;
  background: transparent;
  box-shadow: none;
  padding-top: 0 !important;
}

.Wrapper__Modal.Summary .Wrapper__Body-Table-Header th:last-child,
.Wrapper__Modal.Summary .Wrapper__Body-Table-Body tr > td:last-child {
  display: none;
}

/********************************************
* Wrapper Nav Filter and Content Main Table
*********************************************/
.Wrapper__Body-Content {
  margin: 1rem 3rem !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid var(--border-color-default) !important;
}

.Wrapper__Body-Form ::-webkit-input-placeholder,
.Wrapper__Body-Form.Jax ::-webkit-input-placeholder {
  font-family: "Open Sans" !important;
  color: #9b9b9b !important;
  color: var(--color-placeholder) !important;
  font-size: 14px !important;
}

.Wrapper__Body-Form .default {
  padding: 0 !important;
  width: auto !important;
  border: none !important;
}

.Wrapper__Body-Form label,
.Wrapper__Body-Form.Jax label {
  font-size: 14px !important;
  color: #646464 !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
}

.Wrapper__Body-Form input,
.Wrapper__Body-Form select,
.Wrapper__Body-Form textarea,
.Wrapper__Body-Form .ui.compact.selection.dropdown,
.Wrapper__Body-Form .ui.selection.dropdown,
.Wrapper__Body-Form .visible.menu.transition .item,
.Wrapper__Body-Form.Jax input,
.Wrapper__Body-Form.Jax select,
.Wrapper__Body-Form.Jax textarea,
.Wrapper__Body-Form.Jax .ui.compact.selection.dropdown,
.Wrapper__Body-Form.Jax .ui.selection.dropdown,
.Wrapper__Body-Form.Jax .visible.menu.transition .item {
  font-family: "Open Sans" !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Wrapper__Body-Form input:checked ~ label::before,
.Wrapper__Body-Form.Jax input:checked ~ label::before {
  border-color: #295896 !important;
  border-color: var(--btn-color-primary) !important;
  background-color: #295896 !important;
  background-color: var(--btn-color-primary) !important;
}

.Wrapper__Body-Form input:checked ~ label::after,
.Wrapper__Body-Form.Jax input:checked ~ label::after {
  background-color: #ffffff !important;
  background-color: var(--color-white) !important;
}

.Wrapper__Body-Form i,
.Wrapper__Body-Form.Jax i {
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
  opacity: 1 !important;
  opacity: initial !important;
}

.Wrapper__Body-Form-ButtonAdd {
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
  box-shadow: 0 0 0 1px #295896 inset !important;
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  border-radius: 10px !important;
  padding: 15px !important;
  font-weight: 700 !important;
}

.Wrapper__Body-Form-ButtonAdd.ui.basic.button,
.Wrapper__Body-Form-ButtonAdd.ui.basic.button:hover,
.Wrapper__Body-Form-ButtonAdd.ui.basic.button:active,
.Wrapper__Body-Form-ButtonAdd i {
  font-size: 14px !important;
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
  font-weight: 700 !important;
  opacity: 1 !important;
  opacity: initial !important;
  background-color: transparent !important;
}

.Wrapper__Body-Form-Border {
  border-left: 1px solid #d7d7d7 !important;
  padding-left: 8px !important;
}

.Wrapper__Body-Container-Table {
  overflow-x: auto !important;
}

.Wrapper__Body-Container-Table.MarginTop {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.Wrapper__Body-Table {
  font-family: "Open Sans" !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid var(--border-color-default) !important;
}

.Wrapper__Body-Table-Header tr > th {
  background-color: #ffffff !important;
  background-color: var(--color-white) !important;
  border: none !important;
  border-bottom: 1px solid #646464 !important;
  color: #646464 !important;
  color: var(--font-color) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.Wrapper__Body-Table-Body.ui.accordion > tr.active,
.Wrapper__Body-Table-Body.ui.accordion > tr.title {
  background: none !important;
}

.Wrapper__Body-Table-Body.ui.accordion > tr.content.active {
  background: none !important;
  display: table-row !important;
}

.Wrapper__Body-Table-Header.Small tr > th,
.Wrapper__Body-Table-Body.Small tr > td {
  font-size: 14px !important;
}

.Wrapper__Body-Table-Header.Caps tr > th,
.Wrapper__Body-Table-Body.Caps tr > td {
  text-transform: uppercase !important;
}

.Wrapper__Body-Table-Header-NoHover {
  cursor: default !important;
}

.Wrapper__Body-Loader {
  margin-bottom: 5rem !important;
}

@media screen and (max-width: 1600px) {
  .Wrapper__Body-Table-Header.Small tr > th,
  .Wrapper__Body-Table-Body.Small tr > td {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1400px) {
  .Wrapper__Body-Table-Header tr > th,
  .Wrapper__Body-Table-Body tr > td {
    font-size: 12px !important;
  }

  .Wrapper__Body-Table-Header.Small tr > th {
    font-size: 10px !important;
  }
  .Wrapper__Body-Table-Body.Small tr > td {
    font-size: 10px !important;
  }
}

/*
* Estilos especiales dado a un filtrado customizado
* 1.- Flex Nav Filter
* 2.- DatesRangeInput clean padding
* 3.- Ajust with for inputs
* 4.- Media Query Ajust 1400px
*/
.Wrapper__Body-Form.Jax .inline.field {
  display: flex !important;
  align-items: center !important;
}

.Wrapper__Body-Form.Jax .inline.field div.field {
  padding: 0 !important;
}

.Wrapper__Body-Form.Jax .inline.field input {
  width: 100% !important;
}

@media screen and (max-width: 1400px) {
  .Wrapper__Body-Form.Jax input,
  .Wrapper__Body-Form.Jax select,
  .Wrapper__Body-Form.Jax textarea,
  .Wrapper__Body-Form.Jax .ui.compact.selection.dropdown,
  .Wrapper__Body-Form.Jax .ui.selection.dropdown,
  .Wrapper__Body-Form.Jax .visible.menu.transition .item {
    font-size: 12px !important;
  }

  .Wrapper__Body-Form.Jax label {
    font-size: 12px !important;
  }

  .Wrapper__Body-Form.Jax ::-webkit-input-placeholder {
    font-size: 12px !important;
  }
}

/********************************
* Add, View and Edit Modal Form
*********************************/
.Modal {
  font-family: "Open Sans" !important;
  padding: 3rem !important;
}

.Modal__Form .default {
  padding: 0 !important;
  width: auto !important;
  border: none !important;
}

.Modal__Form label {
  font-size: 14px !important;
  color: #646464 !important;
  color: var(--font-color) !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
}

.Modal__Form input,
.Modal__Form select,
.Modal__Form textarea,
.Modal__Form .ui.compact.selection.dropdown,
.Modal__Form .ui.selection.dropdown,
.Modal__Form .visible.menu.transition .item {
  font-size: 14px !important;
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Modal__Form .disabled.field,
.Modal__Form .disabled.field > label,
.Modal__Form div.disabled.input,
.Modal__Form-SubModal-Form .disabled.field,
.Modal__Form-SubModal-Form > label {
  opacity: 1 !important;
  background-color: transparent !important;
}

.Modal__Form .disabled.field,
.Modal__Form-SubModal-Form .disabled.field {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Modal__Form div.disabled.input {
  padding: 0 !important;
}

.Modal__Form i {
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
  opacity: 1 !important;
  opacity: initial !important;
}

.Modal__Form ::-webkit-input-placeholder {
  color: #9b9b9b !important;
  color: var(--color-placeholder) !important;
  font-size: 14px !important;
}

.Modal__Form-Header.header {
  font-size: 22px !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid var(--border-color-default) !important;
  margin-bottom: 2rem !important;
  padding-bottom: 1rem !important;
}

.Modal__Form-Table {
  border-bottom: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid var(--border-color-default) !important;
}

.Modal__Form-Table-Header tr > th {
  background-color: #ffffff !important;
  background-color: var(--color-white) !important;
  border: none !important;
  border-bottom: 1px solid #646464 !important;
  border-bottom: 1px solid var(--font-color) !important;
  color: #646464 !important;
  color: var(--font-color) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.Modal__Form-Table-Body tr > td {
  color: #646464 !important;
  color: var(--font-color) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.Modal__Form-Table-Header-NoHover {
  cursor: default !important;
}

.Modal__Form-ButtonAdd {
  box-shadow: 0 0 0 1px #295896 inset !important;
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  color: #ffffff !important;
  color: var(--color-white) !important;
  background-color: #295896 !important;
  background-color: var(--btn-color-primary) !important;
  border-radius: 10px !important;
}

.Modal__Form-ButtonAdd.disabled {
  width: auto !important;
}

.Modal__Form-ButtonAdd.Green {
  box-shadow: 0 0 0 1px #7ed320 inset !important;
  box-shadow: 0 0 0 1px var(--color-green-two) inset !important;
  background-color: #7ed320 !important;
  background-color: var(--color-green-two) !important;
}

.Modal__Form-ButtonAdd.Outline {
  box-shadow: 0 0 0 1px #295896 inset !important;
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
  background-color: transparent !important;
  border-radius: 10px !important;
}

.Modal__Form-ButtonAdd i {
  opacity: 1 !important;
  opacity: initial !important;
  color: #ffffff !important;
  color: var(--color-white) !important;
}

.Modal__Form-ButtonAdd.Outline i {
  opacity: 1 !important;
  opacity: initial !important;
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
}

.Modal__Form-ButtonAddOutline {
  box-shadow: 0 0 0 1px #295896 inset !important;
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  border-radius: 10px !important;
  padding: 15px !important;
}

.Modal__Form-ButtonAddOutline.ui.basic.button,
.Modal__Form-ButtonAddOutline.ui.basic.button:hover,
.Modal__Form-ButtonAddOutline.ui.basic.button:active,
.Modal__Form-ButtonAddOutline i {
  font-size: 14px !important;
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
  font-weight: 700 !important;
  opacity: 1 !important;
  opacity: initial !important;
  background-color: transparent !important;
}

.Modal__Form-Utils-Required::after {
  margin: -.2em 0 0 .2em !important;
  content: '*' !important;
  color: #db2828 !important;
}

.Modal__Form-Utils-AlignFlex {
  align-items: flex-end !important;
}

.Modal__Form-Utils-Row-Align {
  justify-content: center !important;
}

.Modal__Form-Utils-Input-MT {
  margin-top: 4px !important;
  margin-bottom: 2px !important;
}

@media screen and (max-width: 1200px) {
  .Modal__Form-Utils-Col-MT {
    margin-top: 14px !important;
  }
}

@media screen and (max-width: 1500px) {
  .Modal__Form-ButtonAdd.Small {
    font-size: 12px !important;
  }
  .Modal__Form-Table-Header.Small tr > th {
    font-size: 12px !important;
  }
  .Modal__Form-Table-Body.Small tr > td {
    font-size: 12px !important;
  }
}

/***********************************************************************
* SubTabNav -> NumericBox and Summary Segment -> Contractors Documents
************************************************************************/
.Modal__SubTabNav-Row-Align {
  justify-content: center !important;
}

.Modal__SubTabNav-SegmentFlex {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 20px !important;
  border-radius: 10px !important;
}

.Modal__SubTabNav-SegmentFlex.Active {
  border: 1px solid #23a71e !important;
  border: 1px solid var(--color-green) !important;
  box-shadow: 0 1px 2px 0 #23a71e !important;
  box-shadow: 0 1px 2px 0 var(--color-green) !important;
}

.Modal__SubTabNav-Numeric {
  border-radius: 10px !important;
  background-color: #fbfbfb !important;
  background-color: var(--color-background) !important;
}

.Modal__SubTabNav-Sumary {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.Modal__SubTabNav-Numeric-Title,
.Modal__SubTabNav-Summary-Title {
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Modal__SubTabNav-Documents-Subtitle {
  font-weight: 300 !important;
  color: #9b9b9b !important;
  color: var(--color-placeholder) !important;
}

.Modal__SubTabNav-Documents-AsSubTitle {
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #9b9b9b !important;
  color: var(--color-placeholder) !important;
  text-transform: none !important;
  text-transform: initial !important;
}

.Modal__SubTabNav-Documents-AsSubTitle + span {
  color: #9b9b9b !important;
  color: var(--color-placeholder) !important;
  font-size: 10px !important;
}

.Modal__SubTabNav-Documents-Title,
.Modal__SubTabNav-SegmentFlex-Title {
  color: #233539 !important;
  color: var(--color-title-module) !important;
  margin: 0 !important;
}

.Modal__SubTabNav-SegmentFlex-Title > span {
  color: #9b9b9b !important;
  color: var(--color-placeholder) !important;
  font-weight: 300 !important;
}

.Modal__SubTabNav-Documents-Indicator {
  color: #23a71e !important;
  color: var(--color-green) !important;
  font-weight: 300 !important;
  font-size: 36px !important;
}
.Modal__SubTabNav-Documents-Indicator i {
  opacity: 0.5 !important;
  font-size: 24px !important;
}

.Modal__SubTabNav-Numeric-Subtitle,
.Modal__SubTabNav-Summary-Subtitle {
  font-weight: 300 !important;
  color: #233539 !important;
  color: var(--color-title-module) !important;
  margin-top: 0 !important;
}

.Modal__SubTabNav-Numeric-Subtitle.Green,
.Modal__SubTabNav-Summary-Subtitle.Green {
  color: #23a71e !important;
  color: var(--color-green) !important;
}

.Modal__SubTabNav-Numeric-Subtitle.Red,
.Modal__SubTabNav-Summary-Subtitle.Red {
  color: #b3373f !important;
  color: var(--color-red) !important;
}

.Modal__SubTabNav-Numeric-Subtitle.Blue,
.Modal__SubTabNav-Summary-Subtitle.Blue {
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
}

.Modal__SubTabNav-Documents-Segment i.link.icon,
.Modal__SubTabNav-Documents-Segment i.clipboard.icon {
  color: #9b9b9b !important;
  color: var(--color-placeholder) !important;
}

.Modal__SubTabNav-Documents-Segment i.circular.icon {
  box-shadow: 0 0 0 0.1em #b3373f inset !important;
  box-shadow: 0 0 0 0.1em var(--color-red) inset !important;
}

.Modal__SubTabNav-Documents-Segment .link {
  padding: 0 !important;
}

.Modal__SubTabNav-Documents-Segment.Siroc {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 3px !important;
  padding: 8px 10px 8px 20px !important;
  margin-bottom: 0 !important;
  box-shadow: none;
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Modal__SubTabNav-Documents-Segment-TextRight {
  text-align: right !important;
  padding: 0 1rem !important;
}
.Modal__SubTabNav-Documents-Segment-TextRight > i.icon {
  padding: 0 20px !important;
}

@media screen and (max-width: 1500px) {
  .Modal__SubTabNav-Summary-Title {
    font-size: 16px !important;
  }
  .Modal__SubTabNav-Summary-Subtitle {
    font-size: 20px !important;
  }
}

/***************************************************
* FromHeader Component and Title Headers Component
****************************************************/
.Modal__Header.header {
  border-bottom: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid var(--border-color-default) !important;
  margin-bottom: 2rem !important;
}

.Modal__Header-Title {
  font-size: 22px !important;
  font-weight: 700 !important;
}

/******************************
* SubModal : Add, Edit, View
******************************/

.Modal__Form-SubModal .default {
  padding: 0 !important;
  width: auto !important;
  border: none !important;
}

.Modal__Form-SubModal,
.Modal__Form-SubModal .row {
  align-items: center !important;
}

.Modal__Form-SubModal-Form {
  padding: 0 2rem 1rem 2rem !important;
}

.Modal__Form-SubModal-Form-Header.header {
  font-size: 22px !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid var(--border-color-default) !important;
  margin-bottom: 2rem !important;
  padding-bottom: 1rem !important;
}

.Modal__Form-SubModal-Form label {
  font-size: 14px !important;
  color: #646464 !important;
  color: var(--font-color) !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
}

.Modal__Form-SubModal-Form input,
.Modal__Form-SubModal-Form select,
.Modal__Form-SubModal-Form textarea,
.Modal__Form-SubModal-Form .ui.compact.selection.dropdown,
.Modal__Form-SubModal-Form .ui.selection.dropdown,
.Modal__Form-SubModal-Form .visible.menu.transition .item {
  font-size: 14px !important;
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Modal__Form-SubModal-Form ::-webkit-input-placeholder {
  color: #9b9b9b !important;
  color: var(--color-placeholder) !important;
  font-size: 14px !important;
}

.Modal__Form-SubModal-Form i {
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
  opacity: 1 !important;
  opacity: initial !important;
}

.Modal__Form-SubModal-Form-ButtonAddOutline {
  box-shadow: 0 0 0 1px #295896 inset !important;
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  border-radius: 10px !important;
  padding: 15px !important;
}

.Modal__Form-SubModal-Form-ButtonAddOutline.Large {
  padding: 12px 15px !important;
}

.Modal__Form-SubModal-Form-ButtonAddOutline.ui.basic.button,
.Modal__Form-SubModal-Form-ButtonAddOutline.ui.basic.button:hover,
.Modal__Form-SubModal-Form-ButtonAddOutline.ui.basic.button:active,
.Modal__Form-SubModal-Form-ButtonAddOutline i {
  font-size: 14px !important;
  color: #295896 !important;
  color: var(--btn-color-primary) !important;
  font-weight: 700 !important;
  opacity: 1 !important;
  opacity: initial !important;
  background-color: transparent !important;
}

.Modal__Form-SubModal-Form-Row-Align {
  justify-content: center !important;
}

.Modal__Form-SubModal-Form-Column-MB {
  margin-bottom: 1rem !important;
}

.Modal__Form-SubModal-Form-ButtonRed {
  box-shadow: 0 0 0 1px #b3373f inset !important;
  box-shadow: 0 0 0 1px var(--color-red) inset !important;
  color: #ffffff !important;
  color: var(--color-white) !important;
  background-color: #b3373f !important;
  background-color: var(--color-red) !important;
  border-radius: 10px !important;
}

.Modal__Form-SubModal-ModalHeader {
  display: flex !important;
  justify-content: space-between !important;
}

.Modal__Form-SubModal-ModalHeader i {
  font-size: 24px !important;
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Modal__ApprovalDocument-Flex {
  display: flex !important;
  justify-content: space-between !important;
}

.Modal__ApprovalDocument-Flex-Center {
  display: flex !important;
  justify-content: center !important;
}

.Modal__ApprovalDocument-Flex h2 {
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Modal__ApprovalDocument-Flex i.folder,
.Modal__ApprovalDocument-Flex-Center i.file {
  color: #b3373f !important;
  color: var(--color-red) !important;
  background-color: #d9d9d9 !important;
  background-color: var(--border-color-default) !important;
  box-shadow: none !important;
}

.Modal__ApprovalDocument-Doc {
  margin-left: 1rem !important;
  max-width: 250px !important;
  text-align: center !important;
}

.Modal__ApprovalDocument-Doc p {
  text-align: left !important;
}

.Modal__ApprovalDocument-BtnRed {
  color: #ffffff !important;
  color: var(--color-white) !important;
  background-color: #b3373f !important;
  background-color: var(--color-red) !important;
}

.Modal__ApprovalDocument-BtnRed:hover {
  background-color: #c33b44 !important;
  background-color: var(--color-red-hover) !important;
}

.Divider {
  border-top: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid var(--border-color-default) !important;
  width: 100% !important;
  margin: 2rem 0 !important;
}

.BtnIconReset {
  background: #ffffff !important;
  background: var(--color-white) !important;
  border-left: 1px solid #dddddd !important;
  border-left: 1px solid var(--color-linner) !important;
  border-top: 1px solid #dddddd !important;
  border-top: 1px solid var(--color-linner) !important;
  border-right: 1px solid #dddddd !important;
  border-right: 1px solid var(--color-linner) !important;
  border-bottom: 1px solid #dddddd !important;
  border-bottom: 1px solid var(--color-linner) !important;
}

.BtnIconReset.NoLeft {
  border-left: none !important;
}

.BtnIconReset > i {
  margin: 0 !important;
}

.BtnIconReset > i::before {
  color: #646464 !important;
  color: var(--font-color) !important;
}

.InputIconReset .ui.icon.input > i.icon:before {
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Util-NPT {
  padding-top: 0 !important;
}

.UtilComplete {
  color: #23a71e !important;
  color: var(--color-green) !important;
}

.UtilNoComplete {
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Util-FlexEnd {
  align-items: flex-end !important;
}

.Util-Siroc-PT {
  padding-top: 4px !important;
}

.Util-Siroc-PTF {
  padding-top: 27px !important;
}

.Util-Full-With {
  width: 100% !important;
}

.Util-Full-With label {
  color: #646464 !important;
  color: var(--font-color) !important;
  font-size: 1rem !important;
  padding: 1rem 0;
}

.Util-Full-With textarea {
  color: #646464 !important;
  color: var(--font-color) !important;
}

.Util-DatePicker > div.field {
  display: block !important;
}

.Util-DatePicker > div.field div.disabled {
  width: 100% !important;
  padding: 0 !important;
}

.Util-DatePicker.Util-MT {
  margin-top: 7px !important;
}

.Util-MaskCurrency {
  padding: 0 !important;
  width: 100% !important;
}

.Pagination-Fix > a.disabled {
  width: auto !important;
}

.container {
  margin-bottom: 80px;
}


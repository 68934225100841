@import "../../settings/variables.css";

.flex-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.flex-group-col {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.flex-children {
  flex: 1;
  margin: 10px;
  flex: 1 0 21%; /* limit to max 4 children per row */
}

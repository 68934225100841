@import "../../settings/variables.css";

.modal-overlay {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  display: none;
  z-index: 9999;
  color: #646464;
}

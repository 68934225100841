.navigation {
  margin: 0px;
  /* width: 300px; */
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
  background: #e5e5e5;
  color: #6b6b6b;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
}

.navigation-right {
  margin: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
  background: #e5e5e5;
  color: #6b6b6b;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
  float: right;
}

.navigation-inner .link-active {
  margin: 0 auto;
  color: #b3363e;
}

@import "../../settings/variables.css";

.default {
  padding: 10px;
  width: 100px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--grey-1);
  color: var(--grey-1);
  cursor: pointer;
}

.action-btn {
  padding: 8px;
  width: 150px;
  background: var(--dusk-blue);
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.ui.button.disabled {
  padding: 8px;
  /*width: 150px;*/
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(41, 88, 150, 0.55);
}

.action-btn-big {
  padding: 10px;
  width: 200px;
  background: var(--primary-blue);
  border: none;
  color: white;
  cursor: pointer;
  font-size: 15px;
}

.action-btn-outline {
  margin: 10px;
  padding: 8px;
  width: 150px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--dusk-blue);
  color: var(--dusk-blue);
  cursor: pointer;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
}

.cancel-btn {
  padding: 8px;
  width: 150px;
  background: var(--grey-4);
  border: none;
  color: var(--grey-6);
  cursor: pointer;
  border-radius: 5px;
}

.icon-btn {
  padding: 10px;
  width: 40px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--grey-1);
  color: var(--grey-6);
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.icon-btn-active {
  padding: 10px;
  width: 40px;
  background: var(--grey-5);
  border: none;
  color: var(--grey-1);
  cursor: pointer;
  border-radius: 5px;
}

.icon-btn-round-primary {
  padding: 5px;
  width: 30px;
  height: 30px;
  background: var(--primary-blue);
  border: none;
  border-radius: 45px;
  color: white;
  cursor: pointer;
}

.icon-btn-round-success {
  padding: 5px;
  width: 30px;
  height: 30px;
  background: var(--success);
  border: none;
  border-radius: 45px;
  color: white;
  cursor: pointer;
}

.icon-btn-round-gray {
  padding: 1px;
  width: 15px;
  height: 15px;
  background: var(--grey-4);
  border: none;
  border-radius: 60px;
  color: white;
  cursor: pointer;
  font-size: 12px;
}

.icon-btn-round-danger {
  padding: 8px;
  width: 150px;
  background: #aa3232;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.icon-btn-edit {
  width: 30px;
  height: 30px;
  border: none;
  color: var(--primary-blue);
  font-size: 18px;
  cursor: pointer;
}

.icon-btn-delete {
  width: 30px;
  height: 30px;
  border: none;
  color: var(--danger);
  font-size: 18px;
  cursor: pointer;
}

.btn-group {
  margin: 0;
  flex: 1;
  padding: 5px;
  border: none;
  border-right: none;
  border-radius: 2px;
  text-align: center;
  font-size: 15px;
  color: var(--white2);
  cursor: pointer;
  background-color: white;
}

.btn-group:focus {
  outline: none;
}

.small {
  width: 30px;
}

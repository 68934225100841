@import "../../settings/variables.css";

.tr {
  margin: 15px auto;
  padding: 1px;
  border-bottom: 1px solid var(--grey-4);
  color: var(--grey-8);
}
.td {
  margin: 10px;
}

@import "../../settings/variables.css";

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  margin: 5px auto;
  padding: 0;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 #eaeaea;
  background-color: white;
}

.divider {
  margin: 0 auto;
  width: 1px;
  height: 20px;
  margin-top: 5px;
  background-color: #dddddd;
}

@import "../../settings/variables.css";

.table-default {
  margin: 0 auto;
  width: 95%;
  font-family: var(--base-font-family);
  text-align: center;
  font-size: 10px;
  color: var(--grey-5);
  border-collapse: collapse;
  border-spacing: 10px;
}

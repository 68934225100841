@import "../../settings/variables.css";

.link {
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 12px;
  color: #9b9b9b;
  cursor: pointer;
}
.link-active {
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  color: var(--dusk-blue);
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.link-active-long {
  text-decoration: none;
  padding-left: 5px;
  padding-right: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  color: var(--dusk-blue);
  background: var(--active-blue);
  border-radius: 4px;
}

.link-bold {
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey-5);
}
.link-active-bold {
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-blue);
  background: var(--active-blue);
  border-radius: 4px;
}

body {
  /**********************************
  * Prevent elements from get selected on mouse drag
  ***********************************/
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

/**********************************
* Variables Project
***********************************/
:root {
  --font-color: #646464;
  --btn-color-primary: #295896;
  --border-color-wrapper: #f2f2f2;
  --border-color-default: #d9d9d9;
  --color-placeholder: #9b9b9b;
  --color-title-module: #233539;
  --color-green: #23a71e;
  --color-green-two: #7ed320;
  --color-red: #b3373f;
  --color-red-hover: #c33b44;
  --color-white: #ffffff;
  --color-background: #fbfbfb;
  --color-linner: #dddddd;
}
/**********************************
* Container With Pages Components
***********************************/
.Wrapper {
  margin: 0 auto !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.Wrapper__Row {
  margin: 0 auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Wrapper__SideBar {
  background: white !important;
  border-right: 1px solid var(--border-color-wrapper) !important;
}

.Wrapper__Body {
  background-color: var(--color-background) !important;
}

.Wrapper__TopBar {
  height: 65px !important;
  background: white !important;
  border-bottom: 1px solid var(--border-color-wrapper) !important;
}

.Wrapper__Nav {
  margin-top: 2rem !important;
  align-items: center !important;
}

.Wrapper__Nav-Title {
  font-family: "Open Sans";
  border: 0 !important;
  font-weight: 700 !important;
  font-size: 38px !important;
  letter-spacing: -1.09px !important;
  background-color: transparent !important;
  color: var(--color-title-module) !important;
}

.Wrapper__Modal {
  margin-top: 2rem !important;
}

.Wrapper__Modal.Summary .Modal__Header .row:first-child {
  display: none !important;
}

.Wrapper__Modal.Summary .Modal {
  border: none;
  background: transparent;
  box-shadow: none;
  padding-top: 0 !important;
}

.Wrapper__Modal.Summary .Wrapper__Body-Table-Header th:last-child,
.Wrapper__Modal.Summary .Wrapper__Body-Table-Body tr > td:last-child {
  display: none;
}

/********************************************
* Wrapper Nav Filter and Content Main Table
*********************************************/
.Wrapper__Body-Content {
  margin: 1rem 3rem !important;
  border-bottom: 1px solid var(--border-color-default) !important;
}

.Wrapper__Body-Form ::-webkit-input-placeholder,
.Wrapper__Body-Form.Jax ::-webkit-input-placeholder {
  font-family: "Open Sans" !important;
  color: var(--color-placeholder) !important;
  font-size: 14px !important;
}

.Wrapper__Body-Form .default {
  padding: 0 !important;
  width: auto !important;
  border: none !important;
}

.Wrapper__Body-Form label,
.Wrapper__Body-Form.Jax label {
  font-size: 14px !important;
  color: #646464 !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
}

.Wrapper__Body-Form input,
.Wrapper__Body-Form select,
.Wrapper__Body-Form textarea,
.Wrapper__Body-Form .ui.compact.selection.dropdown,
.Wrapper__Body-Form .ui.selection.dropdown,
.Wrapper__Body-Form .visible.menu.transition .item,
.Wrapper__Body-Form.Jax input,
.Wrapper__Body-Form.Jax select,
.Wrapper__Body-Form.Jax textarea,
.Wrapper__Body-Form.Jax .ui.compact.selection.dropdown,
.Wrapper__Body-Form.Jax .ui.selection.dropdown,
.Wrapper__Body-Form.Jax .visible.menu.transition .item {
  font-family: "Open Sans" !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  color: var(--font-color) !important;
}

.Wrapper__Body-Form input:checked ~ label::before,
.Wrapper__Body-Form.Jax input:checked ~ label::before {
  border-color: var(--btn-color-primary) !important;
  background-color: var(--btn-color-primary) !important;
}

.Wrapper__Body-Form input:checked ~ label::after,
.Wrapper__Body-Form.Jax input:checked ~ label::after {
  background-color: var(--color-white) !important;
}

.Wrapper__Body-Form i,
.Wrapper__Body-Form.Jax i {
  color: var(--btn-color-primary) !important;
  opacity: initial !important;
}

.Wrapper__Body-Form-ButtonAdd {
  color: var(--btn-color-primary) !important;
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  border-radius: 10px !important;
  padding: 15px !important;
  font-weight: 700 !important;
}

.Wrapper__Body-Form-ButtonAdd.ui.basic.button,
.Wrapper__Body-Form-ButtonAdd.ui.basic.button:hover,
.Wrapper__Body-Form-ButtonAdd.ui.basic.button:active,
.Wrapper__Body-Form-ButtonAdd i {
  font-size: 14px !important;
  color: var(--btn-color-primary) !important;
  font-weight: 700 !important;
  opacity: initial !important;
  background-color: transparent !important;
}

.Wrapper__Body-Form-Border {
  border-left: 1px solid #d7d7d7 !important;
  padding-left: 8px !important;
}

.Wrapper__Body-Container-Table {
  overflow-x: auto !important;
}

.Wrapper__Body-Container-Table.MarginTop {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.Wrapper__Body-Table {
  font-family: "Open Sans" !important;
  border-bottom: 1px solid var(--border-color-default) !important;
}

.Wrapper__Body-Table-Header tr > th {
  background-color: var(--color-white) !important;
  border: none !important;
  border-bottom: 1px solid #646464 !important;
  color: var(--font-color) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.Wrapper__Body-Table-Body.ui.accordion > tr.active,
.Wrapper__Body-Table-Body.ui.accordion > tr.title {
  background: none !important;
}

.Wrapper__Body-Table-Body.ui.accordion > tr.content.active {
  background: none !important;
  display: table-row !important;
}

.Wrapper__Body-Table-Header.Small tr > th,
.Wrapper__Body-Table-Body.Small tr > td {
  font-size: 14px !important;
}

.Wrapper__Body-Table-Header.Caps tr > th,
.Wrapper__Body-Table-Body.Caps tr > td {
  text-transform: uppercase !important;
}

.Wrapper__Body-Table-Header-NoHover {
  cursor: default !important;
}

.Wrapper__Body-Loader {
  margin-bottom: 5rem !important;
}

@media screen and (max-width: 1600px) {
  .Wrapper__Body-Table-Header.Small tr > th,
  .Wrapper__Body-Table-Body.Small tr > td {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1400px) {
  .Wrapper__Body-Table-Header tr > th,
  .Wrapper__Body-Table-Body tr > td {
    font-size: 12px !important;
  }

  .Wrapper__Body-Table-Header.Small tr > th {
    font-size: 10px !important;
  }
  .Wrapper__Body-Table-Body.Small tr > td {
    font-size: 10px !important;
  }
}

/*
* Estilos especiales dado a un filtrado customizado
* 1.- Flex Nav Filter
* 2.- DatesRangeInput clean padding
* 3.- Ajust with for inputs
* 4.- Media Query Ajust 1400px
*/
.Wrapper__Body-Form.Jax .inline.field {
  display: flex !important;
  align-items: center !important;
}

.Wrapper__Body-Form.Jax .inline.field div.field {
  padding: 0 !important;
}

.Wrapper__Body-Form.Jax .inline.field input {
  width: 100% !important;
}

@media screen and (max-width: 1400px) {
  .Wrapper__Body-Form.Jax input,
  .Wrapper__Body-Form.Jax select,
  .Wrapper__Body-Form.Jax textarea,
  .Wrapper__Body-Form.Jax .ui.compact.selection.dropdown,
  .Wrapper__Body-Form.Jax .ui.selection.dropdown,
  .Wrapper__Body-Form.Jax .visible.menu.transition .item {
    font-size: 12px !important;
  }

  .Wrapper__Body-Form.Jax label {
    font-size: 12px !important;
  }

  .Wrapper__Body-Form.Jax ::-webkit-input-placeholder {
    font-size: 12px !important;
  }
}

/********************************
* Add, View and Edit Modal Form
*********************************/
.Modal {
  font-family: "Open Sans" !important;
  padding: 3rem !important;
}

.Modal__Form .default {
  padding: 0 !important;
  width: auto !important;
  border: none !important;
}

.Modal__Form label {
  font-size: 14px !important;
  color: var(--font-color) !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
}

.Modal__Form input,
.Modal__Form select,
.Modal__Form textarea,
.Modal__Form .ui.compact.selection.dropdown,
.Modal__Form .ui.selection.dropdown,
.Modal__Form .visible.menu.transition .item {
  font-size: 14px !important;
  color: var(--font-color) !important;
}

.Modal__Form .disabled.field,
.Modal__Form .disabled.field > label,
.Modal__Form div.disabled.input,
.Modal__Form-SubModal-Form .disabled.field,
.Modal__Form-SubModal-Form > label {
  opacity: 1 !important;
  background-color: transparent !important;
}

.Modal__Form .disabled.field,
.Modal__Form-SubModal-Form .disabled.field {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Modal__Form div.disabled.input {
  padding: 0 !important;
}

.Modal__Form i {
  color: var(--btn-color-primary) !important;
  opacity: initial !important;
}

.Modal__Form ::-webkit-input-placeholder {
  color: var(--color-placeholder) !important;
  font-size: 14px !important;
}

.Modal__Form-Header.header {
  font-size: 22px !important;
  border-bottom: 1px solid var(--border-color-default) !important;
  margin-bottom: 2rem !important;
  padding-bottom: 1rem !important;
}

.Modal__Form-Table {
  border-bottom: 1px solid var(--border-color-default) !important;
}

.Modal__Form-Table-Header tr > th {
  background-color: var(--color-white) !important;
  border: none !important;
  border-bottom: 1px solid var(--font-color) !important;
  color: var(--font-color) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.Modal__Form-Table-Body tr > td {
  color: var(--font-color) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.Modal__Form-Table-Header-NoHover {
  cursor: default !important;
}

.Modal__Form-ButtonAdd {
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  color: var(--color-white) !important;
  background-color: var(--btn-color-primary) !important;
  border-radius: 10px !important;
}

.Modal__Form-ButtonAdd.disabled {
  width: auto !important;
}

.Modal__Form-ButtonAdd.Green {
  box-shadow: 0 0 0 1px var(--color-green-two) inset !important;
  background-color: var(--color-green-two) !important;
}

.Modal__Form-ButtonAdd.Outline {
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  color: var(--btn-color-primary) !important;
  background-color: transparent !important;
  border-radius: 10px !important;
}

.Modal__Form-ButtonAdd i {
  opacity: initial !important;
  color: var(--color-white) !important;
}

.Modal__Form-ButtonAdd.Outline i {
  opacity: initial !important;
  color: var(--btn-color-primary) !important;
}

.Modal__Form-ButtonAddOutline {
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  border-radius: 10px !important;
  padding: 15px !important;
}

.Modal__Form-ButtonAddOutline.ui.basic.button,
.Modal__Form-ButtonAddOutline.ui.basic.button:hover,
.Modal__Form-ButtonAddOutline.ui.basic.button:active,
.Modal__Form-ButtonAddOutline i {
  font-size: 14px !important;
  color: var(--btn-color-primary) !important;
  font-weight: 700 !important;
  opacity: initial !important;
  background-color: transparent !important;
}

.Modal__Form-Utils-Required::after {
  margin: -.2em 0 0 .2em !important;
  content: '*' !important;
  color: #db2828 !important;
}

.Modal__Form-Utils-AlignFlex {
  align-items: flex-end !important;
}

.Modal__Form-Utils-Row-Align {
  justify-content: center !important;
}

.Modal__Form-Utils-Input-MT {
  margin-top: 4px !important;
  margin-bottom: 2px !important;
}

@media screen and (max-width: 1200px) {
  .Modal__Form-Utils-Col-MT {
    margin-top: 14px !important;
  }
}

@media screen and (max-width: 1500px) {
  .Modal__Form-ButtonAdd.Small {
    font-size: 12px !important;
  }
  .Modal__Form-Table-Header.Small tr > th {
    font-size: 12px !important;
  }
  .Modal__Form-Table-Body.Small tr > td {
    font-size: 12px !important;
  }
}

/***********************************************************************
* SubTabNav -> NumericBox and Summary Segment -> Contractors Documents
************************************************************************/
.Modal__SubTabNav-Row-Align {
  justify-content: center !important;
}

.Modal__SubTabNav-SegmentFlex {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 20px !important;
  border-radius: 10px !important;
}

.Modal__SubTabNav-SegmentFlex.Active {
  border: 1px solid var(--color-green) !important;
  box-shadow: 0 1px 2px 0 var(--color-green) !important;
}

.Modal__SubTabNav-Numeric {
  border-radius: 10px !important;
  background-color: var(--color-background) !important;
}

.Modal__SubTabNav-Sumary {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.Modal__SubTabNav-Numeric-Title,
.Modal__SubTabNav-Summary-Title {
  color: var(--font-color) !important;
}

.Modal__SubTabNav-Documents-Subtitle {
  font-weight: 300 !important;
  color: var(--color-placeholder) !important;
}

.Modal__SubTabNav-Documents-AsSubTitle {
  font-size: 16px !important;
  font-weight: 300 !important;
  color: var(--color-placeholder) !important;
  text-transform: initial !important;
}

.Modal__SubTabNav-Documents-AsSubTitle + span {
  color: var(--color-placeholder) !important;
  font-size: 10px !important;
}

.Modal__SubTabNav-Documents-Title,
.Modal__SubTabNav-SegmentFlex-Title {
  color: var(--color-title-module) !important;
  margin: 0 !important;
}

.Modal__SubTabNav-SegmentFlex-Title > span {
  color: var(--color-placeholder) !important;
  font-weight: 300 !important;
}

.Modal__SubTabNav-Documents-Indicator {
  color: var(--color-green) !important;
  font-weight: 300 !important;
  font-size: 36px !important;
}
.Modal__SubTabNav-Documents-Indicator i {
  opacity: 0.5 !important;
  font-size: 24px !important;
}

.Modal__SubTabNav-Numeric-Subtitle,
.Modal__SubTabNav-Summary-Subtitle {
  font-weight: 300 !important;
  color: var(--color-title-module) !important;
  margin-top: 0 !important;
}

.Modal__SubTabNav-Numeric-Subtitle.Green,
.Modal__SubTabNav-Summary-Subtitle.Green {
  color: var(--color-green) !important;
}

.Modal__SubTabNav-Numeric-Subtitle.Red,
.Modal__SubTabNav-Summary-Subtitle.Red {
  color: var(--color-red) !important;
}

.Modal__SubTabNav-Numeric-Subtitle.Blue,
.Modal__SubTabNav-Summary-Subtitle.Blue {
  color: var(--btn-color-primary) !important;
}

.Modal__SubTabNav-Documents-Segment i.link.icon,
.Modal__SubTabNav-Documents-Segment i.clipboard.icon {
  color: var(--color-placeholder) !important;
}

.Modal__SubTabNav-Documents-Segment i.circular.icon {
  box-shadow: 0 0 0 0.1em var(--color-red) inset !important;
}

.Modal__SubTabNav-Documents-Segment .link {
  padding: 0 !important;
}

.Modal__SubTabNav-Documents-Segment.Siroc {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 3px !important;
  padding: 8px 10px 8px 20px !important;
  margin-bottom: 0 !important;
  box-shadow: none;
  color: var(--font-color) !important;
}

.Modal__SubTabNav-Documents-Segment-TextRight {
  text-align: right !important;
  padding: 0 1rem !important;
}
.Modal__SubTabNav-Documents-Segment-TextRight > i.icon {
  padding: 0 20px !important;
}

@media screen and (max-width: 1500px) {
  .Modal__SubTabNav-Summary-Title {
    font-size: 16px !important;
  }
  .Modal__SubTabNav-Summary-Subtitle {
    font-size: 20px !important;
  }
}

/***************************************************
* FromHeader Component and Title Headers Component
****************************************************/
.Modal__Header.header {
  border-bottom: 1px solid var(--border-color-default) !important;
  margin-bottom: 2rem !important;
}

.Modal__Header-Title {
  font-size: 22px !important;
  font-weight: 700 !important;
}

/******************************
* SubModal : Add, Edit, View
******************************/

.Modal__Form-SubModal .default {
  padding: 0 !important;
  width: auto !important;
  border: none !important;
}

.Modal__Form-SubModal,
.Modal__Form-SubModal .row {
  align-items: center !important;
}

.Modal__Form-SubModal-Form {
  padding: 0 2rem 1rem 2rem !important;
}

.Modal__Form-SubModal-Form-Header.header {
  font-size: 22px !important;
  border-bottom: 1px solid var(--border-color-default) !important;
  margin-bottom: 2rem !important;
  padding-bottom: 1rem !important;
}

.Modal__Form-SubModal-Form label {
  font-size: 14px !important;
  color: var(--font-color) !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
}

.Modal__Form-SubModal-Form input,
.Modal__Form-SubModal-Form select,
.Modal__Form-SubModal-Form textarea,
.Modal__Form-SubModal-Form .ui.compact.selection.dropdown,
.Modal__Form-SubModal-Form .ui.selection.dropdown,
.Modal__Form-SubModal-Form .visible.menu.transition .item {
  font-size: 14px !important;
  color: var(--font-color) !important;
}

.Modal__Form-SubModal-Form ::-webkit-input-placeholder {
  color: var(--color-placeholder) !important;
  font-size: 14px !important;
}

.Modal__Form-SubModal-Form i {
  color: var(--btn-color-primary) !important;
  opacity: initial !important;
}

.Modal__Form-SubModal-Form-ButtonAddOutline {
  box-shadow: 0 0 0 1px var(--btn-color-primary) inset !important;
  border-radius: 10px !important;
  padding: 15px !important;
}

.Modal__Form-SubModal-Form-ButtonAddOutline.Large {
  padding: 12px 15px !important;
}

.Modal__Form-SubModal-Form-ButtonAddOutline.ui.basic.button,
.Modal__Form-SubModal-Form-ButtonAddOutline.ui.basic.button:hover,
.Modal__Form-SubModal-Form-ButtonAddOutline.ui.basic.button:active,
.Modal__Form-SubModal-Form-ButtonAddOutline i {
  font-size: 14px !important;
  color: var(--btn-color-primary) !important;
  font-weight: 700 !important;
  opacity: initial !important;
  background-color: transparent !important;
}

.Modal__Form-SubModal-Form-Row-Align {
  justify-content: center !important;
}

.Modal__Form-SubModal-Form-Column-MB {
  margin-bottom: 1rem !important;
}

.Modal__Form-SubModal-Form-ButtonRed {
  box-shadow: 0 0 0 1px var(--color-red) inset !important;
  color: var(--color-white) !important;
  background-color: var(--color-red) !important;
  border-radius: 10px !important;
}

.Modal__Form-SubModal-ModalHeader {
  display: flex !important;
  justify-content: space-between !important;
}

.Modal__Form-SubModal-ModalHeader i {
  font-size: 24px !important;
  color: var(--font-color) !important;
}

.Modal__ApprovalDocument-Flex {
  display: flex !important;
  justify-content: space-between !important;
}

.Modal__ApprovalDocument-Flex-Center {
  display: flex !important;
  justify-content: center !important;
}

.Modal__ApprovalDocument-Flex h2 {
  color: var(--font-color) !important;
}

.Modal__ApprovalDocument-Flex i.folder,
.Modal__ApprovalDocument-Flex-Center i.file {
  color: var(--color-red) !important;
  background-color: var(--border-color-default) !important;
  box-shadow: none !important;
}

.Modal__ApprovalDocument-Doc {
  margin-left: 1rem !important;
  max-width: 250px !important;
  text-align: center !important;
}

.Modal__ApprovalDocument-Doc p {
  text-align: left !important;
}

.Modal__ApprovalDocument-BtnRed {
  color: var(--color-white) !important;
  background-color: var(--color-red) !important;
}

.Modal__ApprovalDocument-BtnRed:hover {
  background-color: var(--color-red-hover) !important;
}

.Divider {
  border-top: none !important;
  border-bottom: 1px solid var(--border-color-default) !important;
  width: 100% !important;
  margin: 2rem 0 !important;
}

.BtnIconReset {
  background: var(--color-white) !important;
  border-left: 1px solid var(--color-linner) !important;
  border-top: 1px solid var(--color-linner) !important;
  border-right: 1px solid var(--color-linner) !important;
  border-bottom: 1px solid var(--color-linner) !important;
}

.BtnIconReset.NoLeft {
  border-left: none !important;
}

.BtnIconReset > i {
  margin: 0 !important;
}

.BtnIconReset > i::before {
  color: var(--font-color) !important;
}

.InputIconReset .ui.icon.input > i.icon:before {
  color: var(--font-color) !important;
}

.Util-NPT {
  padding-top: 0 !important;
}

.UtilComplete {
  color: var(--color-green) !important;
}

.UtilNoComplete {
  color: var(--font-color) !important;
}

.Util-FlexEnd {
  align-items: flex-end !important;
}

.Util-Siroc-PT {
  padding-top: 4px !important;
}

.Util-Siroc-PTF {
  padding-top: 27px !important;
}

.Util-Full-With {
  width: 100% !important;
}

.Util-Full-With label {
  color: var(--font-color) !important;
  font-size: 1rem !important;
  padding: 1rem 0;
}

.Util-Full-With textarea {
  color: var(--font-color) !important;
}

.Util-DatePicker > div.field {
  display: block !important;
}

.Util-DatePicker > div.field div.disabled {
  width: 100% !important;
  padding: 0 !important;
}

.Util-DatePicker.Util-MT {
  margin-top: 7px !important;
}

.Util-MaskCurrency {
  padding: 0 !important;
  width: 100% !important;
}

.Pagination-Fix > a.disabled {
  width: auto !important;
}

.container {
  margin-bottom: 80px;
}

@import "../../settings/variables.css";

.body-conatiner {
  margin: 0 auto;
  width: 94.9%;
  height: 89%;
  float: left;
  background: #fbfbfb;
  overflow-y: auto;
}
